import BackButton from '@/src/components/BackButton'
import styles from '@/src/components/PageTitle/PageTitle.module.scss'
import { Heading } from '@/src/components/Typography'
import { default as classNames, default as cn } from 'classnames'
import React from 'react'

type PageTitleProps = {
  children?: React.ReactNode
  navigateTo?: string
  className?: string
  hideBackButton?: boolean
  theme?: 'light' | 'dark'
  text?: string
  noMargin?: boolean
  defaultMarginBottom?: boolean
  fallBackRoute?: string
  scrollTop?: boolean
}

const PageTitle = ({
  children,
  navigateTo,
  className = '',
  hideBackButton = false,
  theme = 'light',
  text = 'go-back',
  noMargin = false,
  defaultMarginBottom = true,
  fallBackRoute,
  scrollTop,
}: PageTitleProps) => {
  return (
    <div
      className={cn(
        {
          'relative w-full': !className,
          'px-3': className.indexOf('px-') === -1,
        },
        'z-10 m-auto max-w-3xl items-center justify-center overflow-hidden',
        className,
      )}
    >
      {!hideBackButton && (
        <div className={styles.backButtonWrapper}>
          <BackButton
            theme={theme}
            navigateTo={navigateTo}
            text={text}
            scrollTop={scrollTop}
            fallbackRoute={fallBackRoute}
          />
          <div className={styles.line} />
        </div>
      )}
      <div
        className={classNames('text-center', {
          'mt-6': !hideBackButton && !noMargin,
          'mt-4': hideBackButton && !noMargin,
        })}
      >
        <Heading
          level={1}
          className={classNames('uppercase normalPhone:text-2xl', {
            'mb-0': noMargin,
            'mb-4': defaultMarginBottom && !noMargin,
            'mb-2': !defaultMarginBottom && !noMargin,
          })}
        >
          {children}
        </Heading>
      </div>
    </div>
  )
}

export default PageTitle
