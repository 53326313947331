import I18N from '@/src/app/_i18n/i18nConfig.ts'
import Image, { ImageProps } from 'next/image'

const ImageLang = (props: Omit<ImageProps, 'lang'> & { src: string }) => {
  const { src, alt, ...rest } = props
  const lang = I18N.i18n.defaultLocale

  const modifiedSrc = `${src}${src.includes('?') ? '&' : '?'}lang=${lang}`

  return <Image {...rest} src={modifiedSrc} alt={alt} />
}

export default ImageLang
