import throttle from 'lodash/throttle'
import { useCallback, useEffect, useRef, useState } from 'react'

export type SliderProps = HTMLDivElement & {
  prev: () => void
  next: () => void
  moveToIdx: (_idx: number) => void
  scrolledToPos: (_track: SliderTrack) => void
  track?: SliderTrack
  update: () => void
}

export type SliderTrack =
  | {
      reachedEnd: boolean
      reachedStart: boolean
      countPages: number
      currentPage: number
    }
  | undefined

export declare type SliderType = {
  options?: SliderOptions | null
}

export declare type SliderOptions = {
  behavior?: ScrollBehavior
  numberOfItems?: number
}

const useSlider = ({ options = {} }: SliderType) => {
  const slider = useRef<SliderProps | null>(null)
  const [position, setPositionState] = useState({
    x: 0,
    y: 0,
  })

  const getDimensions = useCallback(() => {
    const scrollWidth = slider?.current?.scrollWidth || 0
    const width = slider?.current?.getBoundingClientRect().width || 0
    return { scrollWidth, width }
  }, [slider])

  const getPages = useCallback(() => {
    const { scrollWidth, width } = getDimensions()
    const columns =
      Math.ceil((options?.numberOfItems ?? 0) / 2) || scrollWidth / width

    const columnsPerPage =
      Math.round((columns / (scrollWidth / width)) * 10) / 10
    const columnWidth = width / (columns / (scrollWidth / width))
    const fullColumnsPerPage = Math.floor(columnsPerPage)
    const numberOfPages = Math.ceil(columns / fullColumnsPerPage)

    return {
      numberOfPages,
      itemsPerPage: columnsPerPage,
      itemWidth: columnWidth,
      fullItemsPerPage: fullColumnsPerPage,
    }
  }, [getDimensions, options?.numberOfItems])

  const getCurrentPage = useCallback(() => {
    const { itemWidth, fullItemsPerPage } = getPages()
    return Math.round(
      (slider?.current?.scrollLeft || 0) / (itemWidth * fullItemsPerPage),
    )
  }, [getPages, slider])

  const getCurrentPosition = () => {
    if (!slider?.current) return

    const reachedEnd =
      Math.round(
        slider.current.scrollLeft +
          slider.current.getBoundingClientRect().width,
      ) === slider.current.scrollWidth
    const reachedStart = slider.current.scrollLeft === 0
    const currentPage = getCurrentPage()
    const { numberOfPages: countPages } = getPages()

    return { countPages, currentPage, reachedEnd, reachedStart }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSetPosition = useCallback(
    throttle(() => {
      const pos = getCurrentPosition()
      setPositionState({
        x: slider.current?.scrollLeft || 0,
        y: slider.current?.scrollTop || 0,
      })
      if (pos && slider.current) {
        slider.current.track = pos
        if (typeof slider.current.scrolledToPos === 'function') {
          slider.current.scrolledToPos(pos)
        }
      }
    }, 100),
    [],
  )

  useEffect(() => {
    if (!slider.current) return

    slider.current.prev = () => {
      slider.current?.moveToIdx(getCurrentPage() - 1)
    }

    slider.current.next = () => {
      slider.current?.moveToIdx(getCurrentPage() + 1)
    }

    slider.current.moveToIdx = (idx: number) => {
      const { numberOfPages, fullItemsPerPage, itemWidth } = getPages()
      const safeIdx = Math.min(Math.max(0, idx), numberOfPages - 1)

      slider?.current?.scrollTo({
        left: safeIdx * fullItemsPerPage * itemWidth,
        behavior: options?.behavior || 'smooth',
      })
    }

    slider.current.update = throttledSetPosition

    slider.current.addEventListener('scroll', throttledSetPosition, {
      passive: true,
    })

    window.addEventListener('resize', throttledSetPosition)
    const currentSlider = slider.current

    return () => {
      currentSlider?.removeEventListener('scroll', throttledSetPosition)
      window.removeEventListener('resize', throttledSetPosition)
    }
  }, [
    getPages,
    getCurrentPage,
    options?.behavior,
    throttledSetPosition,
    slider,
    getDimensions,
  ])

  return {
    slider,
    position,
  }
}

export default useSlider
