import I18N from '@/src/app/_i18n/i18nConfig.ts'
const i18n = I18N.i18n

type PathsType = {
  params: { id: string }
}

type Urls = {
  locale: string
} & PathsType

function enabledLanguages() {
  return i18n.locales
}

function generateLocalePaths(paths: PathsType[]) {
  const urls: Urls[] = []

  enabledLanguages().forEach((locale) => {
    paths.forEach((path) => {
      urls.push({ ...path, locale })
    })
  })

  return urls
}

export { enabledLanguages, generateLocalePaths }
