'use client'
import { FlatButton, RoundedButton } from '@/src/components/Button'
import LocalizedLink from '@/src/components/LocalizedLink'
import PaginationDots from '@/src/components/Pagination/PaginationDots'
import styles from '@/src/components/SimpleSlider/SimpleSliderButtons.module.scss'
import SimpleSliderHeader from '@/src/components/SimpleSlider/SimpleSliderHeader'
import Skeleton from '@/src/components/Skeleton'
import { ThumbnailGameProps } from '@/src/components/Thumbnail/GameThumbnail.tsx'
import useSlider, { SliderOptions } from '@/src/hooks/useSlider'
import useSliderDrag from '@/src/hooks/useSliderDrag'
import { GameType } from '@/src/services/types'
import untilFormatter from '@/src/utils/helpers/untilFormatter.ts'
import { default as classNames, default as cn } from 'classnames'
import { ReactElement, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import SimpleSliderGame from '@/src/components/SimpleSlider/SimpleSliderGame.tsx'

type SliderProps = {
  items: ReactElement<ThumbnailGameProps>[]
  title: string
  options?: SliderOptions
  blur?: boolean
  disableShowAll?: boolean
  disableHeader?: boolean
  disableTitleLink?: boolean
  tileSize?: 'small' | 'large'
  slug?: string
  type: string
  subPath?: string
  showSkeleton?: boolean
  rows?: number
}

type Game = GameType & { providerName: string; expirationTime: number }

type SingleSliderProps = {
  items: ReactElement[]
  showButtons?: boolean | 'auto'
  showDots?: boolean
  override?: string
  games?: Game[]
}

const SKELETON_ITEMS = 5

function SimpleSlider({
  items,
  title,
  options = {},
  disableHeader = false,
  disableShowAll = false,
  slug,
  type,
  subPath = '/casino',
  showSkeleton = false,
  disableTitleLink = false,
  rows = 1,
}: SliderProps) {
  const { t } = useTranslation(['common'])

  const {
    handleDrag,
    handleDragEnd,
    handleDragStart,
    slider,
    isMouseDown,
    cancelMomentumTracking,
  } = useSliderDrag({
    options: {
      ...options,
      numberOfItems: items.length,
    },
  })

  useEffect(() => {
    slider.current?.update()
  }, [slider])

  const skeleton =
    showSkeleton &&
    Array(SKELETON_ITEMS)
      .fill({})
      .map((_element, index) => (
        <div
          key={index}
          className='h-72 snap-start p-1 pr-2 slider-item-xs sm:slider-item-sm md:slider-item-md lg:slider-item-lg'
        >
          <div className='h-1/2 overflow-hidden'>
            <Skeleton className='h-full w-full' />
          </div>

          <div className='h-1/2 overflow-hidden pt-1'>
            <Skeleton className='h-full w-full' />
          </div>
        </div>
      ))

  return (
    <div className='tab-4 relative mb-4 px-2 py-2 md:mb-8'>
      <SimpleSliderHeader
        title={title}
        slug={slug}
        slider={slider}
        disable={disableHeader}
        disableShowAll={disableShowAll}
        disableTitleLink={disableTitleLink}
        type={type}
        subPath={subPath}
      />
      <div
        ref={slider}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseMove={handleDrag}
        onMouseLeave={() => {
          handleDragEnd()
          cancelMomentumTracking()
        }}
        onWheel={cancelMomentumTracking}
        className={cn(
          'hide-scroll-bar flex cursor-pointer select-none snap-x snap-mandatory overflow-x-auto active:cursor-grabbing sm:mx-2 sm:px-0',
          styles['scrollbar-hide'],
        )}
      >
        {showSkeleton && skeleton}
        {items.map((game: ReactElement<ThumbnailGameProps>, index) => {
          if (rows === 1 && items.length < 10) {
            return (
              <SimpleSliderGame
                key={index}
                items={items}
                index={index}
                slug={slug}
                game={game}
                isMouseDown={isMouseDown}
              />
            )
          } else {
            if (index % 2 === 0) {
              return (
                <SimpleSliderGame
                  key={index}
                  items={items}
                  index={index}
                  slug={slug}
                  game={game}
                  isMouseDown={isMouseDown}
                  rows={2}
                />
              )
            }
          }
        })}
      </div>

      <div className='mt-5 flex justify-center'>
        <LocalizedLink
          scroll
          href={type == 'games' ? `${subPath}/${slug}` : `/${type}`}
        >
          <RoundedButton type='third' className='mb-6'>
            {t('all-games')}
          </RoundedButton>
        </LocalizedLink>
      </div>
    </div>
  )
}

const Full = ({
  items,
  showButtons = false,
  showDots = true,
  override,
  games,
}: SingleSliderProps) => {
  const { slider } = useSlider({})
  const current = slider?.current

  useEffect(() => {
    current?.update()
  }, [current, items])
  const { t } = useTranslation(['common'])

  return (
    <div
      className={cn([
        styles.singleWrapper,
        { [styles.overSetMaxWidth]: override },
      ])}
    >
      <div className={styles.gallery} ref={slider}>
        <div className={styles.wrapper}>
          {items.map((item: React.ReactElement, i) => (
            <div key={i} className={styles.item}>
              <div className='max-w-full'>{item}</div>
              {games && games?.length > 0 && (
                <div className='mt-1.5 text-sm'>
                  {untilFormatter(games[i]?.expirationTime, t)}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {items.length > 1 && showButtons && (
        <>
          <FlatButton.Round
            onClick={() => slider?.current?.prev()}
            className={classNames([
              styles.navigationButton,
              styles.prev,
              { 'invisible md:visible': showButtons === 'auto' },
              {
                [styles.muted]: current?.track?.reachedStart,
              },
            ])}
          >
            <FaAngleLeft />
          </FlatButton.Round>
          <FlatButton.Round
            onClick={() => slider.current?.next()}
            className={classNames([
              styles.navigationButton,
              styles.next,
              { 'invisible md:visible': showButtons === 'auto' },
              {
                [styles.muted]: current?.track?.reachedEnd,
              },
            ])}
          >
            <FaAngleRight />
          </FlatButton.Round>
        </>
      )}

      {showDots && (
        <div className='flex justify-center pr-3'>
          <PaginationDots
            countPages={items.length}
            currentPage={current?.track?.currentPage || 0}
          />
        </div>
      )}
    </div>
  )
}

export const SimpleSliderFull = memo(Full)
export default memo(SimpleSlider)
