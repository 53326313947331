import { TFunction, TranslationsArr } from 'i18next'

const untilFormatter = (date: number, t: TFunction<TranslationsArr>) => {
  const currentDate = new Date()
  const currentDateMonth = new Date()
  const currentDateWeek = new Date()
  const currentDateDay = new Date()
  const currentDateHours = new Date()
  const currentDateMin = new Date()
  const compareDate = new Date(date)

  const monthDiff = (dateFrom: Date, dateTo: Date) => {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    )
  }

  const daysLeft = (date_1: Date, date_2: Date) => {
    const difference = date_1.getTime() - date_2.getTime()
    return Math.ceil(difference / (1000 * 3600 * 24))
  }

  const getTimeRemaining = (date_1: Date, date_2: Date) => {
    const total = date_1.getTime() - date_2.getTime()
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))
    const week = Math.floor(total / (1000 * 60 * 60 * 24 * 7))

    return {
      total,
      week,
      days,
      hours,
      minutes,
      seconds,
    }
  }

  if (currentDateMonth.setMonth(currentDateMonth.getMonth() + 1) < date) {
    const months = monthDiff(currentDate, compareDate)
    const trans = t(months === 1 ? 'common:month' : 'common:months')
    return `${t('expiresIn')} ${months} ${trans}`
  }

  if (currentDateWeek.setDate(currentDateWeek.getDate() + 8) < date) {
    const days = daysLeft(compareDate, currentDate)
    const weeks = Math.floor(days / 7)
    const trans = t(weeks === 1 ? 'common:week' : 'common:weeks')

    return `${t('expiresIn')} ${weeks} ${trans}`
  }

  if (currentDateDay.setDate(currentDateDay.getDate() + 1) < date) {
    const days = daysLeft(compareDate, currentDate)
    const trans = t(days === 1 ? 'common:day' : 'common:days')
    return `${t('expiresIn')} ${days} ${trans}`
  }

  const { hours } = getTimeRemaining(compareDate, currentDateHours)
  if (hours > 0) {
    const trans = t(hours === 1 ? 'common:hour' : 'common:hours')
    return `${t('expiresIn')} ${hours} ${trans}`
  }

  const { minutes } = getTimeRemaining(compareDate, currentDateMin)
  if (hours === 0 && minutes > 0) {
    const trans = t(minutes === 1 ? 'common:minute' : 'common:minutes')

    return `${t('expiresIn')} ${minutes} ${trans}`
  }
  const { seconds } = getTimeRemaining(compareDate, currentDateMin)
  if (minutes === 0 && seconds > 0) {
    const trans = t(seconds === 1 ? 'common:second' : 'common:seconds')
    return `${t('expiresIn')} ${seconds} ${trans}`
  }

  return t('common:expired')
}

export default untilFormatter
