import styles from '@/src/components/Pagination/Pagination.module.scss'
import classNames from 'classnames'

type Props = {
  countPages: number | undefined | null
  currentPage: number
  reachedEnd?: boolean
  type?: string
  onClick?: (_i: number) => void
}

type DotProps = {
  classes: string
  onClick?: () => void
}

export default function PaginationDots({
  countPages,
  currentPage,
  reachedEnd,
  type,
  onClick,
}: Props) {
  if (countPages == null || countPages < 2) return null

  const isActiveDot = (dotIndex: number) => {
    if (type === 'progress') {
      return currentPage >= dotIndex
    } else {
      if (reachedEnd) {
        // Used for Sliders
        return dotIndex === countPages - 1
      } else {
        return dotIndex === currentPage
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      {Array.from({ length: countPages }, (_, i) => (
        <Dot
          key={i}
          classes={classNames({
            [styles.active]: isActiveDot(i),
            [styles.progress]: type === 'progress',
          })}
          onClick={() => {
            if (typeof onClick === 'function') onClick(i)
          }}
        />
      ))}
    </div>
  )
}

function Dot({ classes, onClick }: DotProps) {
  return <span className={classNames(styles.dot, classes)} onClick={onClick} />
}
