import styles from '@/src/components/Skeleton/Skeleton.module.scss'
import cn from 'classnames'

type SkeletonProps = {
  width?: 25 | 50 | 75 | 100
  fixed?: boolean
  className?: string
}

const Skeleton = ({ width = 100, fixed = false, className }: SkeletonProps) => {
  return (
    <span
      className={cn(
        className,
        styles.root,
        { [styles.fixed]: fixed },
        styles[`width-${width}`],
      )}
    >
      &nbsp;
    </span>
  )
}

export default Skeleton
