'use client'
import { RoundedButton } from '@/src/components/Button'
import LocalizedLink from '@/src/components/LocalizedLink'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import { SerializedPrismicRichText } from '@/src/utils/prismic'
import { relativePathCheck } from '@/src/utils/serializers/RelativeUrl'
import type { PrismicDocument, RichTextField } from '@prismicio/client'
import { usePathname } from 'next/navigation'

type Props = {
  documents: {
    casino: PrismicDocument
    live: PrismicDocument
    sports: PrismicDocument
  }
}
export const PATHS = ['casino', 'live', 'sports'] as const
type Path = (typeof PATHS)[number]

export default function LobbyPromoClient({ documents }: Props) {
  const { isLoggedIn } = useAuthGuard()
  let path = usePathname()?.replace('/sv', '').replace('/', '') as Path
  if (path === ('' as string)) {
    path = 'casino'
  }
  const doc = documents[path]

  if (!PATHS.some((p) => p === path) || !doc) {
    return null
  }

  const header = doc?.data?.promo_header as RichTextField
  const loggedInHeader = doc?.data?.promo_header_logged_in as RichTextField
  const subtitle = doc?.data?.promo_header_logged_in_subtitle as RichTextField

  const cta = Object.assign({}, doc?.data?.cta, {
    label: doc?.data?.cta_label,
  })

  return (
    <>
      {isLoggedIn ? (
        <>
          <SerializedPrismicRichText field={loggedInHeader} />
          {subtitle && <SerializedPrismicRichText field={subtitle} />}
        </>
      ) : (
        <div className='flex flex-col gap-1.5'>
          <SerializedPrismicRichText field={header} />
          <div>
            {cta.label && cta.url && !isLoggedIn && (
              <LocalizedLink href={relativePathCheck(cta.url)}>
                <RoundedButton className='shadow-3xl'>
                  {cta.label}
                </RoundedButton>
              </LocalizedLink>
            )}
          </div>
        </div>
      )}
    </>
  )
}
