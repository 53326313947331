'use client'

import LocalizedLink from '@/src/components/LocalizedLink'
import styles from '@/src/components/Tabs/Tabs.module.scss'
import { usePathname } from '@/src/hooks/usePathname'
import classNames from 'classnames'
import React, { MouseEvent, useState, type JSX } from 'react'

export type TabProps = {
  content?: JSX.Element
  disabled?: boolean
  href?: string
  key?: string
} & InnerHTMLProps

type InnerHTMLProps = {
  icon?: JSX.Element
  name: string
  badge?: string | number | JSX.Element
}

interface TabsProps {
  tabs: TabProps[]
  contentClassName?: string
  className?: string
  onSelect?: (_tab: TabProps) => void
}

const Tabs: React.FC<TabsProps> = ({
  tabs = [],
  className,
  contentClassName,
  onSelect,
}) => {
  const pathname = usePathname()

  const _tabs = tabs.filter((t) => !t.disabled)

  const [activeTab, setActiveTab] = useState(!tabs[0].href && tabs[0].name)

  const handleClick = (e: React.MouseEvent, tab: TabProps) => {
    if (!tab.href) {
      e.preventDefault()
      setActiveTab(tab.name)
    }

    if (typeof onSelect === 'function') onSelect(tab)
  }

  const tabContent = tabs.find((t) => t.name === activeTab)?.content || null

  return (
    <>
      <div className={classNames(styles.tabsRoot, className)}>
        {_tabs.map((tab) => {
          const props = {
            onClick: (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) =>
              handleClick(e, tab),
            className: classNames(styles.tabButton, {
              [styles.selected]:
                tab.name === activeTab || tab.href === pathname,
            }),
          }

          return tab.href ? (
            <LocalizedLink href={tab.href} key={tab.name} {...props}>
              <InnerHtml name={tab.name} icon={tab.icon} badge={tab.badge} />
            </LocalizedLink>
          ) : (
            <button key={tab.name} {...props}>
              <InnerHtml name={tab.name} icon={tab.icon} badge={tab.badge} />
            </button>
          )
        })}
      </div>
      {tabContent && (
        <div {...(contentClassName ? { className: contentClassName } : {})}>
          {tabContent}
        </div>
      )}
    </>
  )
}

const InnerHtml = ({ icon, badge, name }: InnerHTMLProps) => (
  <>
    {icon && <span className={styles.icon}>{icon}</span>}
    <h2>{name}</h2>
    {badge && <span className={styles.badge}>{badge}</span>}
  </>
)

export default Tabs
