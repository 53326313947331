import LocalizedLink from '@/src/components/LocalizedLink'
import cn from 'classnames'
import React from 'react'

type TextLinkProps = {
  href?: string
  onClick?: () => void
  children?: React.ReactNode
  className?: string
  target?: string
}

const TextLink = ({
  href,
  children,
  onClick,
  className,
  target,
}: TextLinkProps) => {
  return href ? (
    <LocalizedLink
      href={href}
      target={target ?? '_self'}
      passHref
      onClick={onClick}
      className={cn(className, 'cursor-pointer font-bold text-white underline')}
    >
      {children}
    </LocalizedLink>
  ) : (
    <a
      onClick={onClick}
      className={cn(className, 'cursor-pointer font-bold text-white underline')}
    >
      {children}
    </a>
  )
}

export default TextLink
