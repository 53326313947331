import useSlider, { SliderOptions } from '@/src/hooks/useSlider'
import { MouseEvent, useCallback, useRef } from 'react'

const useSliderDrag = ({ options }: { options?: SliderOptions }) => {
  const { slider } = useSlider({ options })

  const isMouseDown = useRef(false)

  const mouseCoords = useRef({
    startX: 0,
    scrollLeft: 0,
  })

  const momentum = useRef<{
    velX: number
    momentumID: number | undefined
  }>({
    velX: 0,
    momentumID: undefined,
  })

  const momentumLoop = useCallback(() => {
    if (!slider.current) return

    slider.current.scrollLeft += momentum.current.velX
    momentum.current.velX *= 0.95

    if (Math.abs(momentum.current.velX) > 0.5) {
      momentum.current.momentumID = requestAnimationFrame(momentumLoop)
    }
  }, [slider])

  const cancelMomentumTracking = useCallback(() => {
    cancelAnimationFrame(momentum.current.momentumID || 0)
  }, [])

  const beginMomentumTracking = useCallback(() => {
    cancelMomentumTracking()
    momentum.current.momentumID = requestAnimationFrame(momentumLoop)
  }, [cancelMomentumTracking, momentumLoop])

  const handleDragStart = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!slider.current) return
      const startX = e.pageX - slider.current.offsetLeft
      const scrollLeft = slider.current.scrollLeft
      mouseCoords.current = { startX, scrollLeft }
      isMouseDown.current = true
      cancelMomentumTracking()
    },
    [slider, cancelMomentumTracking],
  )

  const handleDragEnd = useCallback(() => {
    beginMomentumTracking()
    isMouseDown.current = false
  }, [beginMomentumTracking])

  const handleDrag = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!isMouseDown.current || !slider.current) return
      e.preventDefault()
      const x = e.pageX - slider.current.offsetLeft
      const walkX = (x - mouseCoords.current.startX) * 1.5
      const prevScrollLeft = slider.current.scrollLeft

      slider.current.scrollLeft = mouseCoords.current.scrollLeft - walkX

      momentum.current.velX = slider.current.scrollLeft - prevScrollLeft
    },
    [slider],
  )

  return {
    handleDrag,
    handleDragEnd,
    handleDragStart,
    slider, // This may not be necessary since it's already available from useSlider
    isMouseDown,
    cancelMomentumTracking,
  }
}

export default useSliderDrag
