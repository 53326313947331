import Heading from '@/src/components/Typography/Heading'
import Paragraph from '@/src/components/Typography/Paragraph'
import TextLink from '@/src/components/Typography/TextLink'
import { relativePathCheck } from '@/src/utils/serializers/RelativeUrl'
import { FilledLinkToWebField, RTLinkNode } from '@prismicio/client'
import { Element, JSXFunctionSerializer } from '@prismicio/react'
import React from 'react'

const propsWithUniqueKey = function (
  props: Record<string, unknown>,
  key: string | number,
) {
  return Object.assign(props || {}, { key })
}

const HtmlSerializer: JSXFunctionSerializer = (
  type,
  element,
  content,
  children,
  key,
) => {
  const props = {}

  if (type === Element.heading1) {
    return (
      <Heading className='select-text' key={key} {...props} level={1}>
        {children}
      </Heading>
    )
  }

  if (type === Element.heading2) {
    return (
      <Heading className='select-text' key={key} {...props} level={2}>
        {children}
      </Heading>
    )
  }

  if (type === Element.heading3) {
    return (
      <Heading className='select-text' key={key} level={3}>
        {children}
      </Heading>
    )
  }

  if (type === Element.heading4) {
    return (
      <Heading className='select-text' key={key} {...props} level={4}>
        {children}
      </Heading>
    )
  }

  if (type === Element.heading5) {
    return (
      <Heading className='select-text' key={key} {...props} level={5}>
        {children}
      </Heading>
    )
  }

  if (type === Element.heading6) {
    return (
      <Heading className='select-text' key={key} {...props} level={6}>
        {children}
      </Heading>
    )
  }

  if (type === Element.list) {
    return (
      <ul
        key={key}
        {...props}
        className='mb-6 flex list-inside list-disc flex-col space-y-6'
      >
        {children}
      </ul>
    )
  }

  if (type === Element.oList) {
    return (
      <ol
        key={key}
        {...props}
        className='mb-6 list-inside list-decimal marker:font-bold'
      >
        {children}
      </ol>
    )
  }

  if (type === Element.hyperlink) {
    const data = (element as RTLinkNode).data as FilledLinkToWebField
    const href = data.url ?? '/'
    const target = data.target ?? '_self'
    return (
      <TextLink
        target={target}
        href={relativePathCheck(href)}
        key={key}
        {...props}
      >
        {children}
      </TextLink>
    )
  }

  if (type === Element.paragraph) {
    return (
      <Paragraph className='select-text' key={key} {...props}>
        {children}
      </Paragraph>
    )
  }

  if (type === Element.em) {
    return React.createElement('em', propsWithUniqueKey(props, key), children)
  }
  if (type === Element.listItem) {
    return (
      <li key={key} {...props}>
        {children}&nbsp;
      </li>
    )
  }
  if (type === Element.oListItem) {
    return (
      <li key={key} {...props}>
        {children}
      </li>
    )
  }
  /* if (type === Element.image) {
    const linkUrl = element.linkTo ? element.linkTo.url : null
    const linkRel = target ? { rel: 'noopener' } : {}

    const img = (
      <ImageLang
        src={element.url}
        width={element.dimensions.width}
        height={element.dimensions.height}
        loading='lazy'
        alt={element.alt || ''}
      />
    )
  } */

  return null
}

export default HtmlSerializer
