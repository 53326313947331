import { default as cn } from 'classnames'

const SimpleSliderGame = ({
  items,
  index,
  slug,
  game,
  isMouseDown,
  rows = 1,
}) => {
  return (
    <div
      key={`${slug || ''}-${game.key}`}
      className={cn(
        'shrink-0 snap-start p-2 slider-item-xs sm:slider-item-sm md:slider-item-md lg:slider-item-lg',
        { 'pointer-events-none': isMouseDown.current },
      )}
    >
      {game}
      {items[index + 1] && rows === 2 && (
        <div className='pt-4'>{items[index + 1]}</div>
      )}
    </div>
  )
}

export default SimpleSliderGame