import { RefObject, useCallback } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import PaginationDots from '@/src/components/Pagination/PaginationDots'
import styles from '@/src/components/SimpleSlider/SimpleSliderButtons.module.scss'
import { SliderProps } from '@/src/hooks/useSlider'
import classNames from 'classnames'

type SimpleSliderButtonsProps = {
  slider: RefObject<SliderProps | null>
  title?: string
}

const disabledStateClasses = 'opacity-30'

const SliderButtons = ({ slider }: SimpleSliderButtonsProps) => {
  const sliderPosition = slider?.current?.track
  const handleClick = useCallback(
    (i: number) => {
      slider.current?.moveToIdx?.(i)
    },
    [slider],
  )

  const handlePrev = () => {
    slider.current?.prev()
  }

  const handleNext = () => {
    slider.current?.next()
  }

  return sliderPosition ? (
    <>
      <div className='flex lg:hidden'>
        <PaginationDots
          countPages={sliderPosition.countPages}
          currentPage={sliderPosition.currentPage}
          reachedEnd={sliderPosition.reachedEnd}
          onClick={handleClick}
        />
      </div>
      <div className='hidden lg:flex'>
        <div
          className={classNames(styles.button, {
            [disabledStateClasses]: sliderPosition.reachedStart,
          })}
          onClick={() => handlePrev()}
        >
          <FaAngleLeft />
        </div>
        <div
          className={classNames(styles.button, {
            [disabledStateClasses]: sliderPosition.reachedEnd,
          })}
          onClick={() => handleNext()}
        >
          <FaAngleRight />
        </div>
      </div>
    </>
  ) : null
}

export default SliderButtons
