'use client'
import styles from '@/src/components/BackButton/BackButton.module.scss'
import LocalizedLink from '@/src/components/LocalizedLink'
import useRouter from '@/src/hooks/Router/useRouter'
import { usePathname } from '@/src/hooks/usePathname'
import {
  getLastVisitedLobby,
  getLastVisitedPage,
} from '@/src/utils/localStorage'
import cn from 'classnames'
import { TranslationsArr, TranslationsType } from 'i18next'
import { debounce } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdKeyboardArrowLeft } from 'react-icons/md'

export type BackButtonProps = {
  className?: string
  navigateTo?: string | null
  theme?: 'light' | 'dark'
  delay?: number
  text?: string
  noText?: boolean
  transdoc?: TranslationsType | TranslationsArr
  skipFunc?: boolean
  fallbackRoute?: string | null
  scrollTop?: boolean
}

const BackButtonClient = ({
  className = '',
  navigateTo = null,
  theme = 'light',
  text = 'go-back',
  noText = false,
  transdoc = 'common',
  fallbackRoute = null,
  scrollTop = true,
}: BackButtonProps) => {
  const pathname = usePathname()
  const router = useRouter()
  const { t } = useTranslation(transdoc)

  const backText = noText ? '' : t(text as 'go-back')

  const goBack = debounce((e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault()

    const lvp = getLastVisitedPage()
    if (!lvp) {
      if (fallbackRoute) {
        return router.push(fallbackRoute)
      }
      return router.push(getLastVisitedLobby(), { scroll: scrollTop })
    }

    if (lvp !== pathname) {
      return router.push(lvp, { scroll: scrollTop })
    }

    if (lvp === pathname && fallbackRoute) {
      return router.push(fallbackRoute)
    }

    router.back()
  }, 200)

  return navigateTo ? (
    <LocalizedLink
      href={navigateTo}
      role='navigation'
      className={cn(
        className,
        styles.root,
        styles[theme],
        'cursor-pointer',
        { 'h-8 w-8 rounded-full p-0': noText },
        { 'h-8 w-fit rounded px-2.5': !noText },
      )}
    >
      <MdKeyboardArrowLeft
        className={cn(
          {
            '': noText,
          },
          {
            'mr-0.5 opacity-60': !noText,
          },
        )}
        size={noText ? '1.5rem' : '1rem'}
      />
      <span className={styles.backBtnTxt}>{backText}</span>
    </LocalizedLink>
  ) : (
    <button
      onClick={goBack}
      onTouchStart={goBack}
      role='navigation'
      className={cn(
        className,
        styles.root,
        styles[theme],
        'cursor-pointer',
        { 'h-8 w-8 rounded-full p-0': noText },
        { 'h-8 w-fit rounded px-2.5': !noText },
      )}
    >
      <MdKeyboardArrowLeft
        className={cn(
          {
            '': noText,
          },
          {
            'mr-0.5 opacity-60': !noText,
          },
        )}
        size={noText ? '1.5rem' : '1rem'}
      />
      {!noText && <span className={styles.backBtnTxt}>{backText}</span>}
    </button>
  )
}

export default BackButtonClient
