'use client'

import Banner, { BannerProps } from '@/src/components/Banner'
import { SimpleSliderFull } from '@/src/components/SimpleSlider/SimpleSlider'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import { useCallback, useMemo } from 'react'

type SliderProps = {
  items: Array<BannerProps>
  className?: string
}

export default function BannerSlider({ items, className }: SliderProps) {
  const { isLoggedIn } = useAuthGuard()

  const filterItems = useCallback(
    ({ primary = {} }: BannerProps) => {
      const { visible_to } = primary
      const isOf = ['all', isLoggedIn ? 'logged in' : 'logged out']
      return (
        visible_to &&
        isOf.indexOf(visible_to.toLowerCase()) > -1 &&
        !!primary.slug
      )
    },
    [isLoggedIn],
  )

  const carouselItems = useMemo(
    () =>
      items
        ?.filter(filterItems)
        .map(({ primary }) => (
          <Banner key={`banner-${primary.slug}`} primary={primary} />
        )),
    [items, filterItems],
  )

  return carouselItems.length > 0 ? (
    <div className={className}>
      <SimpleSliderFull items={carouselItems} />
    </div>
  ) : null
}
