import { SupportedLangsType } from '@/src/app/_i18n/settings'
import ImageLang from '@/src/components/ImageLang'
import PageTitle from '@/src/components/PageTitle'
import { BonusType } from '@/src/services/types/bonus'
import htmlSerializer from '@/src/utils/htmlSerializer'
import { getClient } from '@/src/utils/prismic-client'
import { enabledLanguages } from '@/src/utils/translation.tsx'
import {
  PrismicDocument,
  RTImageNode,
  RichTextField,
  asText,
} from '@prismicio/client'
import { PrismicRichText, PrismicText } from '@prismicio/react'

// const BONUS_TYPE_CAMPAIGN = 'campaign'
// const BONUS_TYPE_VOUCHER = 'voucher'

// Serialize Prismic Rich Text field
const SerializedPrismicRichText = ({ field }: { field?: RichTextField }) => (
  <PrismicRichText field={field} components={htmlSerializer} />
)

// Serialize Prismic Rich Text field
const SerializedPrismicPageTitle = ({ field }: { field: RichTextField }) => (
  <PageTitle>
    <PrismicText field={field} />
  </PageTitle>
)

// Generate image component from Prismic Image field
const RichImage = ({ alt, dimensions, url, ...other }: RTImageNode) =>
  !url ? null : (
    <ImageLang
      src={url}
      width={dimensions.width}
      height={dimensions.height}
      alt={alt || ''}
      {...other}
    />
  )

const extractTextValue = (node?: RichTextField) => {
  if (!node) return ''
  return asText(node)
}

/**
 *
 * @param bonuses array of bonuses
 * @param documents array of prismic documents
 * @returns bonus list with content
 */
export function applyContentToBonuses(
  bonuses: BonusType[],
  documents: PrismicDocument[],
) {
  if (!bonuses || !documents || bonuses.length === 0) return []
  return bonuses.map((bonus) => {
    const document = documents.find((d) => `${d.uid}` === `${bonus.slug}`)
    return {
      ...bonus,
      content: document,
    }
  })
}
/**
 *
 * @param type Prismic document type
 * @param lang Prismic language code
 * @returns array of paths for given document type
 */
async function generatePathsForDocumentType(
  type: string,
  lang: SupportedLangsType | '*',
) {
  const client = getClient(lang)
  const documents = await client.getAllByType(type)

  return documents
    .filter(
      (doc) =>
        enabledLanguages().findIndex(
          (item) => doc.lang.toLowerCase() === item.toLowerCase(),
        ) > -1,
    )
    .map(({ uid }) => {
      return { uid: uid }
    })
}

export {
  RichImage,
  SerializedPrismicPageTitle,
  SerializedPrismicRichText,
  extractTextValue,
  generatePathsForDocumentType,
}
