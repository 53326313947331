'use client'
import styles from '@/src/components/Banner/Banner.module.scss'
import ImageLang from '@/src/components/ImageLang'
import LocalizedLink from '@/src/components/LocalizedLink'
import { asText } from '@prismicio/client'
import classNames from 'classnames'
import { useMemo } from 'react'
import { FiChevronRight } from 'react-icons/fi'

export type BannerProps = {
  primary: {
    image?: {
      alt?: string
      url: string
      dimensions: {
        width: number
        height: number
      }
    }
    title?: string
    description?: string
    visible_to?: string
    slug?: string
  }
  className?: string
}

const IMAGE_DIMENSIONS = {
  width: 56,
  height: 56,
}
const IMAGE_TRANSFORMS = {
  mask: 'ellipse',
}

const Banner = ({ primary, className }: BannerProps) => {
  const { description, image, title, slug } = primary || {}

  const imageTransformsQuery = new URLSearchParams(IMAGE_TRANSFORMS).toString()
  const imageSource = image?.url && `${image.url}&${imageTransformsQuery}`

  let _title = title
  let _description = description

  if (typeof title === 'object') {
    _title = asText(title)
  }

  if (typeof description === 'object') {
    _description = asText(description)
  }
  const bannerChildren = useMemo(
    () => (
      <>
        {imageSource && (
          <div
            className={styles.image}
            style={{
              ...IMAGE_DIMENSIONS,
              minWidth: IMAGE_DIMENSIONS.width,
            }}
          >
            <ImageLang
              src={imageSource}
              alt={image.alt || ''}
              height={IMAGE_DIMENSIONS.height}
              width={IMAGE_DIMENSIONS.width}
              className='h-full'
            />
          </div>
        )}
        <div className={styles.content}>
          <span className={styles.title}>{_title}</span>
          <small className={styles.description}>{_description}</small>
        </div>
        <div className={styles.append}>
          <FiChevronRight size={20} />
        </div>
      </>
    ),
    [imageSource, image?.alt, _title, _description],
  )
  return slug ? (
    <div className='px-3 pb-2'>
      <LocalizedLink
        className={classNames(styles.banner, className)}
        data-testid='banner'
        href={slug}
      >
        {bannerChildren}
      </LocalizedLink>
    </div>
  ) : null
}

export default Banner
