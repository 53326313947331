import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/BannerSlider/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/LobbyPromo/LobbyPromo.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/LobbyPromo/LobbyPromoClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lucky-sweden/lucky-sweden/src/components/Tabs/Tabs.tsx");
