const luckyLinks = [
  'localhost:3000',
  'lucky-ang-stage-gl.onladv.com',
  'lucky-stage-caon.onladv.com',
  'luckycasino.com',
  'lucky-ontario-stage-gl.onladv.com',
  'luckycasino.ca',
  'lucky-stage-gl.onladv.com',
  'lucky-dev.onladv.com',
  'lucky-dev2.onladv.com'
]
export const relativePathCheck = (url: string) => {
  if (url.includes('mailto:')) return url
  if (luckyLinks.some((element) => url.includes(element))) {
    return new URL(url).pathname
  }
  return url
}
