import LocalizedLink from '@/src/components/LocalizedLink'
import SimpleSliderButtons from '@/src/components/SimpleSlider/SimpleSliderButtons'
import { Heading } from '@/src/components/Typography'
import { SliderProps } from '@/src/hooks/useSlider'
import { RefObject } from 'react'
import { FaAngleRight } from 'react-icons/fa'

type SliderHeaderProps = {
  title: string
  slug?: string
  slider: RefObject<SliderProps | null>
  disableShowAll: boolean
  disableTitleLink?: boolean
  disable: boolean
  currentSlide?: number
  type?: string
  subPath?: string
}

const SliderHeader = (props: SliderHeaderProps) => {
  const { disable, subPath = '/casino', slug, slider, ...rest } = props
  const href = props.type == 'games' ? `${subPath}/${slug}` : `${props.type}`

  return disable ? null : (
    <div className={'mx-4 mb-1 flex justify-between sm:mb-2 lg:mb-5 lg:mt-2'}>
      {rest.disableTitleLink ? (
        <h3 className='select-none self-center text-lg font-semibold uppercase'>
          {props.title}
        </h3>
      ) : (
        <LocalizedLink href={href} passHref>
          <Heading level={2} className='mb-0'>
            {props.title} <FaAngleRight className={'inline'} />
          </Heading>
        </LocalizedLink>
      )}{' '}
      <SimpleSliderButtons slider={slider} />
    </div>
  )
}

export default SliderHeader
