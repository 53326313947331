import I18N from '@/src/app/_i18n/i18nConfig.ts'
import { SupportedLangsType } from '@/src/app/_i18n/settings.tsx'
import { getPrismicLang } from '@/src/app/_i18n/utils/i18nHelper.ts'
import {
  filter,
  getRepositoryEndpoint,
  createClient as prismicCreateClient,
} from '@prismicio/client'

export const repositoryName = process.env.NEXT_PUBLIC_PRISMIC_REPO || 'luckyweb'

const endpoint = getRepositoryEndpoint(repositoryName)

const createClient = (lang: SupportedLangsType | '*') => {
  return prismicCreateClient(endpoint, {
    accessToken: process.env.PRISMIC_ACCESS_TOKEN,
    fetchOptions: {
      next: { revalidate: 3600, tags: ['prismic'] },
    },
    defaultParams: {
      lang: lang === '*' ? '*' : getPrismicLang(lang),
    },
  })
}

const client = createClient(I18N.i18n.defaultLocale)

const getClient = (lang: SupportedLangsType | '*') => {
  // client?.defaultParams?.lang = getPrismicLang(lang)
  if (!client?.defaultParams) {
    client.defaultParams = {}
  }

  client.defaultParams.lang = getPrismicLang(lang)

  return client
}

export { createClient, filter, getClient }
